import React from "react";
import { Route, Switch } from "react-router-dom";
import Loadable from 'react-loadable';
import LoadingComponent from './components/LoadingComponent';

const Frontpage = Loadable({ loader: () => import("./containers/Frontpage"), loading: LoadingComponent });
const NotFound = Loadable({ loader: () => import("./containers/NotFound"), loading: LoadingComponent });

// eslint-disable-next-line
export default (({ childProps }) => (
  <Switch>
    <Route path="/" exact component={Frontpage} props={childProps} />
    <Route component={NotFound} />
  </Switch>
  ))
