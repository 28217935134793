import React, { Component } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import Routes from './Routes';

class App extends Component {
  render() {
    const childProps = {
    };

    return (
      <MuiThemeProvider theme={theme}>
        <Routes childProps={childProps} />
      </MuiThemeProvider>
    );
  }
}

export default App;
