import { createMuiTheme } from '@material-ui/core/styles';
import styleVars from './styleVariables.scss';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontSize: 13,
    fontFamily: ['Roboto','sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: "#002544",
      contrastText: '#fff'
    },
  },

  overrides: {
    MuiListItem: {
      button: {
        "&:hover": {
          backgroundColor: styleVars.brightPrimary,
          "& .fbMuiListItemFix h3": {
            color: styleVars.brightPrimaryText
          }
        }
      }
    }
  },
  props: {
    MuiListItemText: {
      className: "fbMuiListItemFix"
    }
  }
});

export default theme;
