import React from 'react';
import styles from './../styles/General.module.scss';

export default function LoadingComponent({isLoading, error, relative}) {
  if (isLoading) {
    return <div className={styles.spinner}></div>
  } else if (error) {
    return <div className={styles.pageLoadError}>Sorry, there was a problem loading the page.</div>
  }
  else {
    return null
  }
}
